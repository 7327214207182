<script setup>

import { Vue3Marquee } from "vue3-marquee";

const backendStore = useBackendStore();

const carCount = backendStore.carCount.value;
const currentDate = await getServerDate();

const updateDate = dateToTextPL(getLastWorkingDayDate(), false);
const updateHour = computed(() => {
  return getLastWorkingDayDate() === formatDate(currentDate) ? currentDate.getHours() : 23;
})

const infoBarItems = [
  {caption: `<b>${carCount}</b> dostępnych samochodów`, duration: 5000},
  // {caption: 'tylko <strong>DOSTĘPNE</strong> samochody', duration: 5000},
  {caption: `ostatnia aktualizacja: <b>${updateDate} ${updateHour.value}:00</b>`, duration: 5000},
]

const infoBarLabel = ref(infoBarItems[0].caption);
const show = ref(true);

let currentIndex = 0;

</script>

<template>
  <div class="flex w-full bg-black py-2.5 justify-center items-center min-w-80">
    <ClientOnly fallback-tag="div">
      <div :class="['text-white transition-opacity leading-tight font-extralight text-[14px] overflow-x-hidden w-full',{'opacity-100':show, 'opacity-0':!show}]">
        <Vue3Marquee>
          <template v-for="(bar,idx) in infoBarItems">
            <div v-html="bar.caption" />
            <div class="sm:last:hidden bg-white w-1.5 h-1.5 mx-4 mt-0.5"></div>
          </template>
        </Vue3Marquee>
      </div>
    </ClientOnly>
  </div>
</template>
